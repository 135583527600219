import { useParams } from '@remix-run/react';
import React, { useMemo } from 'react';

import { type EnumsPageName } from '@lp-lib/api-service-client/public';

import {
  type GameLikeClickedProps,
  toGameLikeFilters,
  useGameLikeEmitterForList,
  useGameLikeEventEmitter,
  useGameLikeTagPaginator,
  useGameLikeWorkspace,
  useSetupGameLikeSearch,
  useTriggerManagePinnedGamePacksModal,
} from '../../components/Game/GameCenter';
import {
  GameLikeListWrapper,
  useGameLikeListLoader,
} from '../../components/Game/GameCenter/List';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  type GamePackCardStyles,
  MyGamePackBadge,
} from '../../components/Game/GamePack/GamePackCard';
import { useUser } from '../../components/UserContext';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useTitle } from '../../hooks/useTitle';
import { safeWindowReload } from '../../logger/logger';
import {
  apiService,
  type GamePacksResponse,
  type Paginator,
} from '../../services/api-service';
import { RoleUtils } from '../../types';
import { GameLikeQueryType, type GamePack } from '../../types/game';
import { type Tag } from '../../types/tag';
import { makeTitle } from '../../utils/common';
import { getGridStyle } from '../../utils/css';
import { useGamePackContext, useGamePackLoader, useNumPerRow } from './Context';
import { GamePackCardBottomAccessory } from './GamePackCardBottomAccessory';

interface GamePackListProps {
  type: GameLikeQueryType;
  paginator: Paginator<GamePacksResponse, GamePack>;
  keyword?: string | number | null;
  breadcrumb?: string;
  onGamePackClick?: (pack: GamePack) => void;
  filter?: { enabled?: boolean; visible?: boolean };
  pinnedManager?: JSX.Element;
  containerClassName?: string;
  badgeAccessory?: (pack: GamePack) => React.ReactNode;
  showVersion?: boolean;
  bottomAccessory?: (pack: GamePack) => React.ReactNode;
}

interface ParamTypes {
  slug: string;
}

export const GamePackList = (props: GamePackListProps): JSX.Element => {
  const { type, keyword, breadcrumb, paginator, filter, pinnedManager } = props;
  const ctx = useGamePackContext();
  const emitter = useGameLikeEventEmitter('gamePack');
  const { items, dao, state, error, handleRetry, handleLoadMore } =
    useGameLikeListLoader<GamePacksResponse, GamePack>(paginator);
  const numPerRow = useNumPerRow();
  const remainingSpots = numPerRow - (items.length % numPerRow);
  const loader = useGamePackLoader(
    remainingSpots === numPerRow ? remainingSpots : remainingSpots + numPerRow
  );
  const [, setActiveGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const gamePackStyles = useMemo<GamePackCardStyles>(() => {
    return {
      size: 'w-full',
    };
  }, []);

  const handleCardClick = (pack: GamePack) => {
    if (props.onGamePackClick) {
      props.onGamePackClick(pack);
      return;
    }
    setActiveGamePack(pack);
  };

  useGameLikeEmitterForList(emitter, dao, type, keyword);

  return (
    <GameLikeListWrapper
      type='gamePack'
      queryType={type}
      breadcrumbs={ctx.breadcrumbs}
      breadcrumb={breadcrumb}
      filter={filter}
      showEmptyMessage={
        !paginator.hasMore() && items.length === 0 && !state.isRunning
      }
      showErrorMessage={!!error}
      canLoad={state.isStarted && !state.isRunning && !error}
      handleRetry={handleRetry}
      handleLoad={handleLoadMore}
      pinnedManager={pinnedManager}
      containerClassName={props.containerClassName}
    >
      <div
        className='grid gap-y-6 gap-x-3 pt-4'
        style={{ gridTemplateColumns: getGridStyle(numPerRow) }}
      >
        {items.map((p) => (
          <GamePackCard
            key={p.id}
            gamePack={p}
            onClick={handleCardClick}
            badges={
              props.badgeAccessory ? (
                props.badgeAccessory(p)
              ) : (
                <>
                  <MyGamePackBadge gamePack={p} />
                  <DefaultGamePackCardBadges gamePack={p} />
                </>
              )
            }
            bottomAccessory={
              props.bottomAccessory ? (
                props.bottomAccessory(p)
              ) : (
                <GamePackCardBottomAccessory pack={p} />
              )
            }
            styles={gamePackStyles}
            showVersion={props.showVersion ?? !ctx.embed}
          />
        ))}
        {state.isRunning && loader}
      </div>
    </GameLikeListWrapper>
  );
};

export const MyGamePackList = (
  props: GameLikeClickedProps<GamePack>
): JSX.Element => {
  const paginator = useMemo(() => apiService.gamePack.getMyGamePacks(), []);
  useTitle(makeTitle('My Game Packs'));
  return (
    <GamePackList
      type={GameLikeQueryType.My}
      paginator={paginator}
      breadcrumb={'My Game Packs'}
      onGamePackClick={props.onItemClick}
    />
  );
};

export const TagGamePackList = (
  props: GameLikeClickedProps<GamePack> & { tag?: Tag }
): JSX.Element | null => {
  const ctx = useGamePackContext();
  const { slug } = useParams<keyof ParamTypes>();
  useTitle(makeTitle(`#${slug}`), { disable: ctx.embed });
  const managePinnedGamePacks = useTriggerManagePinnedGamePacksModal();
  const isAdmin = RoleUtils.isAdmin(useUser());
  const { inited, tag, paginator } = useGameLikeTagPaginator({
    slug: slug ?? '',
    getPaginatorByTagId: useLiveCallback((tagId) => {
      return apiService.gamePack.getGamePacksByTagId(tagId, !isAdmin);
    }),
    initTag: props.tag,
  });

  if (!inited || !tag || !paginator) {
    return null;
  }

  const pinnedManager = (
    <button
      type='button'
      className='btn-secondary h-10 w-50'
      onClick={() => {
        managePinnedGamePacks({
          tag,
          onComplete: () => {
            safeWindowReload();
          },
        });
      }}
    >
      Reorder Game Packs
    </button>
  );

  return (
    <GamePackList
      type={GameLikeQueryType.ByTags}
      keyword={tag.id}
      breadcrumb={tag.name}
      paginator={paginator}
      onGamePackClick={props.onItemClick}
      pinnedManager={isAdmin ? pinnedManager : undefined}
    />
  );
};

export const SearchGamePackList = (
  props: GameLikeClickedProps<GamePack> & {
    search?: string;
    primeOnly?: boolean;
    scope?: EnumsPageName;
    breadcrumb?: string;
    noFilter?: boolean;
    badgeAccessory?: (pack: GamePack) => React.ReactNode;
  }
): JSX.Element | null => {
  const ctx = useGamePackContext();
  const { q, breadcrumb, inited, showFilter, filterOptions } =
    useSetupGameLikeSearch({
      type: 'gamePack',
      embed: ctx.embed,
      search: props.search,
      breadcrumb: props.breadcrumb,
    });
  useTitle(makeTitle(q), { disable: ctx.embed });

  const paginator = useMemo(
    () =>
      apiService.gamePack.searchGamePacks(q, {
        filterParams: toGameLikeFilters(filterOptions),
        primeOnly: props.primeOnly,
        scope: props.scope,
      }),
    [q, filterOptions, props.primeOnly, props.scope]
  );

  if (!inited) return null;

  return (
    <GamePackList
      type={GameLikeQueryType.Search}
      keyword={q}
      breadcrumb={breadcrumb}
      paginator={paginator}
      filter={{ enabled: !props.noFilter, visible: showFilter }}
      onGamePackClick={props.onItemClick}
      badgeAccessory={props.badgeAccessory}
    />
  );
};

export const UntaggedGamePackList = (
  props: GameLikeClickedProps<GamePack>
): JSX.Element => {
  const paginator = useMemo(
    () => apiService.gamePack.getUntaggedPrimeGamePacks(),
    []
  );
  useTitle(makeTitle('Not Featured'));
  return (
    <GamePackList
      type={GameLikeQueryType.Untagged}
      paginator={paginator}
      breadcrumb={'Not Featured'}
      onGamePackClick={props.onItemClick}
    />
  );
};

export const PlayAgainGamePackList = (
  props: GameLikeClickedProps<GamePack>
): JSX.Element => {
  const paginator = useMemo(() => apiService.gamePack.getPlayedGamePacks(), []);
  useTitle(makeTitle('Play Again'));
  return (
    <GamePackList
      type={GameLikeQueryType.Played}
      paginator={paginator}
      breadcrumb={'Play Again'}
      onGamePackClick={props.onItemClick}
    />
  );
};
